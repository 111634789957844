import {
  Box,
  Button,
  chakra,
  Container,
  Flex,
  Text,
  shouldForwardProp,
  Heading,
} from '@chakra-ui/react';
import {
  motion,
  useMotionValue,
  useScroll,
  useDragControls,
  isValidMotionProp,
} from 'framer-motion';
import JSONData from '../data-target/targets.json';

import { useStaticQuery, graphql } from 'gatsby';
import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';
import { PortfolioSliderItem } from './PortfolioSliderItem';
import { CountPageDot } from './CountPageDot';
const AnimatedComponent = chakra(motion.div, {
  shouldForwardProp: (prop) =>
    isValidMotionProp(prop) || shouldForwardProp(prop),
});

function PortfolioSlider({ backG }) {
  console.log(JSONData);
  const CarouselTrack = useRef();
  const CarouselContainer = useRef();

  const [CarouselTrackWidth, setCarouselTrackWidth] = useState();
  const [CarouselWidth, setCarouselWidth] = useState();

  const [countSlidePages, setCountSlidepages] = useState(0);
  const [currentSlidePage, setCurrentSlidepages] = useState(0);

  const [CarouselTrackOffset, setCarouselTrackOffset] = useState(0);
  const [breakpoints, setBreakpoints] = useState([]);

  const controls = useDragControls();

  useEffect(() => {
    function updateSize() {
      const width = CarouselContainer.current.offsetWidth;
      setCarouselTrackWidth(CarouselTrack.current.offsetWidth);
      setCarouselWidth(width);
      setCarouselTrackOffset(0);
      let countPages = Math.ceil(
        CarouselTrack.current.scrollWidth /
          CarouselContainer.current.offsetWidth
      );
      setCountSlidepages(countPages);

      const newBreakepoints = [];
      for (let i = 0; i <= countPages; i++) {
        newBreakepoints[i] = width * i;
      }
      setBreakpoints(newBreakepoints);
      setCurrentSlidepages(0);
    }
    window.addEventListener('resize', updateSize);
    updateSize();
    return () => window.removeEventListener('resize', updateSize);
  }, []);

  const x = useMotionValue(0);
  useEffect(() => {
    x.onChange(() => {
      console.log('Damn it');
      console.log(x.get());
    });
  }, []);

  function startDrag(event, info) {
    controls.start(event);
    const firstIndex = CarouselTrack.current.style.transform.indexOf('-');
    const lastIndex = CarouselTrack.current.style.transform.indexOf('p');
    const transformX = Number(
      CarouselTrack.current.style.transform.slice(firstIndex + 1, lastIndex)
    );

    for (let i = 0; i < breakpoints.length; i++) {
      if (transformX <= breakpoints[i + 1]) {
        //console.log(transformX, breakpoints[i]);
        setCurrentSlidepages(i);
        return;
      }
    }

    // console.log('offsetX', event.offsetX);
    // console.log('clientX', event.clientX);
    // console.log('screenX', event.screenX);
    // console.log('x', event.x);
    // console.log('point', info.point);
    // console.log('delta', info.delta);
    // console.log('offset', info.offset);
    // console.log(CarouselTrack);
    // console.log(CarouselTrack.current.style.transform);
    // console.log(CarouselTrack.current.offsetLeft);
  }

  function endDrag(event, info) {
    setTimeout(() => {
      console.log('this is the third message');
      console.log('END:' + info.point.x, info.point.y);

      const firstIndex = CarouselTrack.current.style.transform.indexOf('-');
      const lastIndex = CarouselTrack.current.style.transform.indexOf('p');
      const transformX = Number(
        CarouselTrack.current.style.transform.slice(firstIndex + 1, lastIndex)
      );

      for (let i = 0; i < breakpoints.length; i++) {
        if (transformX <= breakpoints[i + 1]) {
          console.log('setCurrent' + transformX, breakpoints[i]);
          setCurrentSlidepages(i);
          return;
        }
      }
    }, 1000);
  }

  return (
    console.log(breakpoints),
    (
      <Box bg={backG}>
        <Container
          ref={CarouselContainer}
          overflow="hidden"
          pb={24}
          variant="layoutContainer">
          <AnimatedComponent
            layout
            w={'fit-content'}
            ref={CarouselTrack}
            animate={{
              x: CarouselTrackOffset,
            }}
            transition={{
              type: 'spring',
              damping: 50,
              mass: 2.75,
              stiffness: 100,
            }}
            onDirectionLock={(axis) => console.log('AXIS' + axis)}
            dragConstraints={{
              right: 150,
              left: -(CarouselTrackWidth - CarouselWidth + 550),
            }}
            onDragStart={startDrag}
            onDragEnd={endDrag}
            dragElastic={1}
            drag="x"
            dragControls={controls}
            dragTransition={{
              power: 0.5,
              timeConstant: 100,
              modifyTarget: (target) => Math.round(target / 300) * 300,
            }}
            display="flex"
            flexDirection={'row'}
            flexGrow={1}
            pt={12}>
            {JSONData.map((link) => (
              <>
                <PortfolioSliderItem
                  as={motion.div}
                  btnVariant={'solid'}
                  params={link}
                />
              </>
            ))}
          </AnimatedComponent>
        </Container>
        {/* <Heading>{currentSlidePage}</Heading> */}
        <Container variant="layoutContainer">
          <Flex
            mx={'auto'}
            maxW={'4xl'}
            pb={8}
            px={9}
            justify={'center'}>
            <Button
              position={'relative'}
              px={4}
              pr={4}
              mr={4}
              bg="brand.green"
              textAlign={'center'}
              borderRadius="sm"
              onClick={() => {
                if (currentSlidePage > 0) {
                  console.log('Fire FOR EFFECT LEFT******');

                  setCarouselTrackOffset(
                    -CarouselWidth * (currentSlidePage - 1)
                  );
                  setCurrentSlidepages(currentSlidePage - 1);
                }
              }}>
              &larr;
            </Button>
            <Flex
              display={{ base: 'none', lg: 'flex' }}
              flexWrap="wrap"
              pt={2}
              pb={8}
              justify={'center'}
              maxW="50%">
              {Array(countSlidePages)
                .fill()
                .map((a, index) => (
                  <CountPageDot
                    mr={4}
                    activeCurrentSlide={currentSlidePage}
                    index={index}
                    onClick={() => {
                      setCarouselTrackOffset(-breakpoints[index]);
                      setCurrentSlidepages(index);
                      console.log(-breakpoints[index]);
                    }}
                  />
                ))}
            </Flex>
            <Button
              position={'relative'}
              px={4}
              pr={4}
              ml={4}
              bg="brand.green"
              textAlign={'center'}
              borderRadius="sm"
              onClick={() => {
                if (currentSlidePage < countSlidePages - 1) {
                  console.log('Fire FOR EFFECT Right******');
                  setCarouselTrackOffset(
                    -CarouselWidth * (currentSlidePage + 1)
                  );
                  setCurrentSlidepages(currentSlidePage + 1);
                }
              }}>
              &rarr;
            </Button>
          </Flex>
        </Container>
      </Box>
    )
  );
}

export default PortfolioSlider;
